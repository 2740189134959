<template>
  <div class="reports-preview">
    <div class="headline">
      <h1 class="title">
        Pregled zapisnika
      </h1>
      <div class="action">
        <Button class="btn-primary" @click="generateReport">
          <i class="el-icon-printer"></i>
        </Button>
      </div>
    </div>
    <vue-html2pdf
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :filename="`Zapisnik br. ${operatingItem.item.content.id}`"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf">
      <section class="pdf-content" slot="pdf-content">
        <div class="main">
          <div class="header">
            <div class="logo">
              <img src="/photos/logo/logo.jpg" alt="">
            </div>
            <div class="information">
              Ravnanje automobilskog lima bez lakiranja
            </div>
          </div>
          <div class="content">
            <!-- Report number -->
            <div class="row space-between align-items-end">
              <div class="col">
                <div class="title">
                  Zapisnik štete br. {{ operatingItem.item.content.id }}
                </div>
              </div>
              <div class="col">
                <div class="information">
                  <strong>Datum:</strong> {{ operatingItem.item.createdAt | moment("DD.MM.YYYY.") }}
                </div>
              </div>
            </div>
            <!-- Company -->
            <div class="row">
              <div class="col">
                <div class="information">
                  <strong>Stranka:</strong> {{ operatingItem.item.content.client.content.company.name }}
                </div>
              </div>
              <div class="col">
                <div class="information">
                  <strong>Adresa:</strong> {{ operatingItem.item.content.client.content.company.address }}
                </div>
              </div>
              <div class="col">
                <div class="information">
                  <strong>Porezni broj:</strong> {{ operatingItem.item.content.client.content.company.vatNumber }}
                </div>
              </div>
            </div>
            <!-- Contact -->
            <div class="row">
              <div class="col">
                <div class="information">
                  <strong>Kontakt osoba:</strong> {{ operatingItem.item.content.client.content.contact.name }}
                </div>
              </div>
              <div class="col">
                <div class="information">
                  <strong>E-mail:</strong> {{ operatingItem.item.content.client.content.contact.email }}
                </div>
              </div>
              <div class="col">
                <div class="information">
                  <strong>GSM:</strong> {{ operatingItem.item.content.client.content.contact.mobilePhone }}
                </div>
              </div>
            </div>
            <!-- Vehicle -->
            <div class="row">
              <div class="col">
                <div class="information">
                  <strong>Tip vozila:</strong> {{ operatingItem.item.content.vehicle.type }}
                </div>
              </div>
              <div class="col">
                <div class="information">
                  <strong>Registracija:</strong> {{ operatingItem.item.content.vehicle.registrationNumber }}
                </div>
              </div>
              <div class="col">
                <div class="information">
                  <strong>Broj šasije:</strong> {{ operatingItem.item.content.vehicle.vin }}
                </div>
              </div>
              <div class="col">
                <div class="information">
                  <strong>Boja vozila:</strong> {{ operatingItem.item.content.vehicle.color }}
                </div>
              </div>
            </div>
            <!-- Insurance -->
            <div class="row">
              <div class="col">
                <div class="information">
                  <strong>Osiguranje:</strong> {{ operatingItem.item.content.insurance.content.name }}
                </div>
              </div>
              <div class="col">
                <div class="information">
                  <strong>Izvid štete:</strong> {{ operatingItem.item.content.damageReport }}
                </div>
              </div>
            </div>
            <!-- Damage List -->
            <div class="damage">
              <div class="damage-photo">
                <div class="photo" ref="photo">
                  <img src="/photos/reports/vehicle.jpg" alt="">
                </div>
              </div>
              <div class="notes">
                <div class="row">
                  <div class="col">
                    <div class="information">
                      <strong>Stanje brojača:</strong> {{ operatingItem.item.content.additionalInformation.counterStatus }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="information">
                      <strong>RS:</strong> {{ operatingItem.item.content.additionalInformation.hourWorked }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="information">
                      <strong>Procjenu obavio:</strong> {{ operatingItem.item.content.additionalInformation.reporter }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Calculation -->
            <div class="calculation" :class="{ 'separated': operatingItem.item.content.damageList.length > 6 }">
              <div class="row">
                <div class="col">
                  <div class="title">
                    Obračun:
                  </div>
                </div>
                <div class="col">
                  <div class="calculation-list">
                    <div class="item">
                      <strong>Hladno ravnanje lima:</strong>{{ showAmountWithCurrency(operatingItem.item.createdAt, operatingItem.item.content.calculation.damageTotal) }}
                    </div>
                    <div class="item">
                      <strong>Demontaža + Montaža:</strong> {{ showAmountWithCurrency(operatingItem.item.createdAt, operatingItem.item.content.calculation.assemblyDisassembly) }}
                    </div>
                    <div class="item">
                      <strong>Rabat:</strong> {{ operatingItem.item.content.calculation.discount.toFixed(2) }}%
                    </div>
                    <div class="item">
                      <strong>Lakiranje:</strong> {{ showAmountWithCurrency(operatingItem.item.createdAt, operatingItem.item.content.calculation.lacquering) }}
                    </div>
                    <div class="item">
                      <strong>Dijelovi:</strong> {{ showAmountWithCurrency(operatingItem.item.createdAt, operatingItem.item.content.calculation.parts) }}
                    </div>
                    <div class="item">
                      <strong>Ukupno bez PDV-a:</strong>  {{ showAmountWithCurrency(operatingItem.item.createdAt, operatingItem.item.content.calculation.totalWithoutVAT) }}
                    </div>
                    <div class="item">
                      <strong>PDV 25%:</strong> {{ showAmountWithCurrency(operatingItem.item.createdAt, operatingItem.item.content.calculation.vat) }}
                    </div>
                    <div class="item">
                      <strong>Ukupno s PDV-om:</strong> {{ showAmountWithCurrency(operatingItem.item.createdAt, operatingItem.item.content.calculation.totalWithVAT) }}
                    </div>
                  </div>
                </div> 
                <div class="col">
                  <div class="signatures">
                    <div class="item">
                      Potpis i pečat stranke pri predaji vozila na popravak
                    </div>
                    <div class="item">
                      Potpis i pečat stranke pri preuzimanju vozila s popravka
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer">
            TUČA d.o.o., Pavla Lončara 47, 10290 Zaprešić <br>
            Tel.: 01/3313-653 <br>
            <div class="information">
              Tvrtka je upisana u sudski registar Trgovačkog suda u Zagrebu 13.6.2005. pod brojem TT-05/5653-2. Osnivački kapital od 20.000 KN uplaćen je u cijelosti.
              <br>
              MBS: 080526817 · OIB: 62510534055
            </div>
          </div>
        </div>
        <div class="html2pdf__page-break"></div>
        <div class="additional-note">
          <div class="row">
            <div class="col">
              <div class="information">
                <strong>Napomena:</strong> {{ operatingItem.item.content.notes.note }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Element UI
import {Row, Col, Button} from 'element-ui';

// Logic
export default {
  name: 'AdministrationReportsPreviewIndex',
  mixins: [Common],
  components: {
    Row,
    Col,
    Button
  },
  data() {
    return {
      limitDate: '2024-02-16',
      operatingItem: {
        item: {
          content: {
            id: '',
            client: {
              id: '',
              content: {
                company: {
                  name: '',
                  address: '',
                  vatNumber: ''
                },
                contact: {
                  name: '',
                  phone: '',
                  mobilePhone: '',
                  email: '',
                }
              }
            },
            vehicle: {
              type: '',
              registrationNumber: '',
              vin: '',
              color: ''
            },
            insurance: {
              id: '',
              content: {
                name: '',
                list: []
              }
            },
            damageReport: '',
            damageList: [],
            additionalInformation: {
              counterStatus: 0,
              hoursWorked: 0,
              reporter: ''
            },
            calculation: {
              damageTotal: 0,
              assemblyDisassembly: 0,
              lacquering: 0,
              parts: 0,
              discount: 0,
              totalWithoutVAT: 0,
              vat: 0,
              totalWithVAT: 0
            },
            notes: {
              note: '',
              internalNote: ''
            }
          },
          createdAt: null
        }
      }
    }
  },
  computed: {
    toggleSecondCurrency() {
      if (this.operatingItem?.item?.createdAt) {
        return this.operatingItem.item.createdAt.slice(0,10) <= this.limitDate;
      }
      return false;
    }
  },
  methods: {
    setDamageMarker() {
      document.querySelectorAll(".marker").forEach(e => e.remove());
      this.operatingItem.item.content.damageList.forEach((item, index) => {
        const marker = document.createElement('div');
        const type = item.type === 'parking' ? 'P' : item.quantity;
        const innerHtmlDoubleCurrency = `<div>${type} | <strong>${item.cost.toFixed(2)} HRK | ${this.currencyConverter(item.cost)} EUR</strong><div class="remove" id="remove${index}">Ukloni</div></div>`;
        const innerHtmlEuroOnly = `<div>${type} | <strong>${item.cost.toFixed(2)} € </strong><div class="remove" id="remove${index}">Ukloni</div></div>`;
        const innerHtmlContent = this.toggleSecondCurrency ? innerHtmlDoubleCurrency : innerHtmlEuroOnly;

        marker.classList.add('marker');
        marker.style.top = `${item.y}px`;
        marker.style.left = `${item.x}px`;
        marker.innerHTML = innerHtmlContent;
        this.$refs.photo.appendChild(marker);
      });
    },
    currencyConverter(price) {
      return (price/7.53450).toFixed(2)
    },
    getOperatingItem() {
      this.setLoadingComponent(true);
      this.Axios.get(`administration/report/${this.$route.params.id}/get`).then(response => {
        if (response.data.content.data) {
          this.operatingItem.item = {
            id: response.data.content.data.id,
            content: {
              id: response.data.content.data.content.id,
              client: response.data.content.data.content.client,
              vehicle: response.data.content.data.content.vehicle,
              insurance: response.data.content.data.content.insurance,
              damageReport: response.data.content.data.content.damageReport,
              damageList: response.data.content.data.content.damageList,
              additionalInformation: response.data.content.data.content.additionalInformation,
              calculation: response.data.content.data.content.calculation,
              notes: response.data.content.data.content.notes,
            },
            createdAt: response.data.content.data.created_at
          };
        }
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
          this.operatingItem = {
            item: {
              content: {
                id: '',
                client: {
                  id: '',
                  content: {
                    company: {
                      name: '',
                      address: '',
                      vatNumber: ''
                    },
                    contact: {
                      name: '',
                      phone: '',
                      mobilePhone: '',
                      email: '',
                    }
                  }
                },
                vehicle: {
                  type: '',
                  registrationNumber: '',
                  vin: '',
                  color: ''
                },
                insurance: {
                  id: '',
                  content: {
                    name: '',
                    list: []
                  }
                },
                damageReport: '',
                damageList: [],
                additionalInformation: {
                  counterStatus: 0,
                  hoursWorked: 0,
                  reporter: ''
                },
                calculation: {
                  damageTotal: 0,
                  assemblyDisassembly: 0,
                  lacquering: 0,
                  discount: 0,
                  totalWithoutVAT: 0,
                  vat: 0,
                  totalWithVAT: 0
                },
                notes: {
                  note: '',
                  internalNote: ''
                }
              },
              createdAt: null
            }
          };
        }
      }).finally(() => {
        this.setDamageMarker();
        this.setLoadingComponent(false);
      });
    },
    getType(data) {
      return data === 'hail' ? "Tuča" : "Parking";
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    currencyConverter(amount) {
      return (amount/7.53450).toFixed(2)
    },
    showAmountWithCurrency(date, amount) {
      if(!date)
        return
      const dateSliced = date.slice(0,10)
      if (dateSliced <= this.limitDate)
        return `${amount.toFixed(2)} HRK | ${this.currencyConverter(amount)} €`
      return `${amount.toFixed(2)} €`
    },
  },
  created() {
    this.initialize();
    this.getOperatingItem();
  },
  mounted() {
    this.setDamageMarker();
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';

.content-wrapper {
  margin: 0 auto 0;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.15);
}

.pdf-content {
  position: relative;
  padding: 24px 24px;
  font-family: 'Arial', serif;
  color: black;

  .main {
    .header {
      position: relative;
      display: flex;
      justify-content: center;

      .logo {
        width: 180px;
      }

      .information {
        position: absolute;
        top: 16px;
        right: 0;
        width: 130px;
        font-size: 12px;
        font-style: italic;
        text-align: right;
        text-transform: uppercase;
      }
    }

    .content {
      margin-top: 24px;

      .row {
        display: flex;

        &.space-between {
          justify-content: space-between;

          .col {
            flex: unset;
          }
        }

        &.align-items-end {
          align-items: flex-end;
        }

        + .row {
          margin-top: 8px;
        }

        .col {
          display: inline-flex;
          flex: 1;

          + .col {
            margin-left: 16px;
          }
        }
      }

      .title {
        font-size: 14px;
        font-weight: 700;
        padding-bottom: 8px;
      }

      .information {
        flex: 1;
        font-size: 10px;
        border-bottom: 1px solid $grey-200;
        padding-bottom: 6px;

        strong {
          margin-right: 4px;
        }
      }

      .damage {
        border: 1px solid $grey-200;
        margin-top: 16px;
        padding: 2px;

        .photo {
          position: relative;
          border: 1px solid $grey-200;

          .marker {
            position: absolute;
            width: auto;
            height: auto;
            background-color: $grey-600;
            font-size: 10px;
            color: white;
            text-align: center;
            padding: 3px;
            border-radius: 4px;

            div {
              + div {
                margin-top: 4px;
              }
            }
          }
        }

        .damage-list {
          padding: 4px 8px 0;

          .item {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $grey-200;
            font-size: 9px;
            padding-bottom: 8px;
            margin-bottom: 0;

            &:first-child {
              font-weight: 700;
            }

            &:last-child {
              border-bottom: 0;
              padding-bottom: 0;
              margin-bottom: 0;
            }

            .number,
            .type,
            .description,
            .cost {
              width: 25%;
            }
          }
        }

        .notes {
          padding: 16px 8px 16px;
        }
      }

      .calculation {
        border: 1px solid $grey-200;
        margin-top: 16px;
        padding: 8px 8px 16px;

        .row {
          .col:nth-child(1) {
            flex: unset;
            width: 20%;
          }
          .col:nth-child(2) {
            flex: unset;
          }
          .col:nth-child(3) {
            flex: 1;
            justify-content: center;
            align-items: center;
          }
        }

        .title {
          text-transform: uppercase;
          padding: 0;
          border-bottom: 0;
        }

        .calculation-list {
          .item {
            display: flex;
            justify-content: space-between;
            font-size: 9px;
            padding-top: 6px;

            &:first-child {
              padding-top: 0;
            }

            strong {
              margin-right: 16px;
            }
          }
        }

        .signatures {
          .item {
            font-size: 8px;
            text-transform: uppercase;
            text-align: center;
            border-top: 1px solid $grey-200;
            padding-top: 2px;

            + .item {
              margin-top: 24px;
            }
          }
        }
      }
    }

    .footer {
      margin-top: 64px;
      font-size: 10px;
      line-height: 1.4;

      .information {
        text-transform: uppercase;
      }
    }
  }

  .additional-note {
    font-size: 12px;
    border-bottom: 1px solid $grey-200;
    padding-bottom: 6px;
    margin-top: 24px;
  }
}
</style>

<style lang="scss" scoped>
.headline {
  margin-bottom: 24px;
}
</style>
